import {
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpGridOffset,
  OwnUpGridWrapper,
  OwnUpIcon,
  ownUpMidtoneTheme,
  OwnUpThemeProvider
} from '@rategravity/own-up-component-library';
import React, { PropsWithChildren } from 'react';
import { ContentWrapper, StyledIconHeadingSection } from './elements';

export interface IconHeadingSectionProps {
  icon: () => JSX.Element;
}

export const IconHeadingSection = ({
  icon,
  children,
  ...props
}: PropsWithChildren<IconHeadingSectionProps>) => (
  <OwnUpThemeProvider theme={ownUpMidtoneTheme}>
    <StyledIconHeadingSection {...props}>
      <OwnUpGridWrapper>
        <OwnUpGridContainer variant="legacy">
          <OwnUpGridOffset xs={0} md />
          <OwnUpGridItem xs={12} md={10} lg={8}>
            <OwnUpIcon icon={icon} style={{ justifyContent: 'center' }} height="64px" />
            <ContentWrapper>{children}</ContentWrapper>
          </OwnUpGridItem>
          <OwnUpGridOffset xs={0} md />
        </OwnUpGridContainer>
      </OwnUpGridWrapper>
    </StyledIconHeadingSection>
  </OwnUpThemeProvider>
);
