import {
  FOG_100,
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpGridOffset
} from '@rategravity/own-up-component-library';
import React from 'react';
import styled from 'styled-components';

const DividerLine = styled.hr`
  height: 1px;
  background-color: ${FOG_100};
  border: none;
  margin: 0;
`;

export const Divider = () => (
  <OwnUpGridContainer variant="legacy">
    <OwnUpGridOffset xs={0} lg={1} />
    <OwnUpGridItem xs style={{ padding: '0 inherit', margin: 'auto 0' }}>
      <DividerLine />
    </OwnUpGridItem>
    <OwnUpGridOffset xs={0} md={2} lg={3} />
  </OwnUpGridContainer>
);
