import {
  IconLink,
  OwnUpFillButtonPrimary,
  PropsWithTheme
} from '@rategravity/own-up-component-library';
import { ArrowRightIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/arrow-right';
import { Link } from 'gatsby';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

const MethodologyLinkWrapper = styled.div`
  // Spacing above link
  padding-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2)}px;
`;

export const MethodologyLink = ({ children }: PropsWithChildren<{}>) => (
  <MethodologyLinkWrapper>
    <IconLink
      size="large"
      icon={ArrowRightIcon}
      iconPlacement="right"
      link="/methodology"
      component={Link}
      to="/methodology"
    >
      {children}
    </IconLink>
  </MethodologyLinkWrapper>
);

const GetStartedButtonLink = styled.div`
  display: block;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.only('xs')} {
    width: 100%;
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('xs')} {
    margin-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(4)}px;
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('lg')} {
    margin-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(5)}px;
  }
`;

export const GetStartedCta = ({
  linkText,
  url,
  onClick
}: {
  linkText: string;
  url: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}) => (
  <GetStartedButtonLink>
    <OwnUpFillButtonPrimary component={Link} to={url} onClick={onClick} id="get_started_paystoshop">
      {linkText}
    </OwnUpFillButtonPrimary>
  </GetStartedButtonLink>
);
