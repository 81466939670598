import { PropsWithTheme } from '@rategravity/own-up-component-library';
import styled from 'styled-components';

export const StyledIconHeadingSection = styled.section`
  background-color: ${({ theme }: PropsWithTheme<{}>) => theme.palette.background.default};

  // Spacing above and below content
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('xs')} {
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(6, 0)};
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.only('md')} {
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(8, 0)};
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('lg')} {
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(10, 0)};
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  // Spacing between icon and content
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('xs')} {
    padding-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(3)}px;
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('lg')} {
    padding-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(4)}px;
  }
`;
